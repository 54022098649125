import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  AllocateButton,
  UnAllocateButton,
  ImportButton,
  ExportButton
} from "../../../.././../Common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import { handleServerErrors,handleMultiFilterSearch } from "../../utiles/helpers";
import DialogWithTable from "../common/DialogWithTable";
import _ from "lodash";
import useFrontendTable from "../../utiles/useFrontendTable";
import usePresentationCRUD from "../../utiles/usePresentationCRUD";
import { BigLoader } from "components/Common";
import * as XLSX from 'xlsx';
import { convertUtcToLocal, dateFormatter, getTimeZoneDifference } from "utils/helpers";


const filterLabels = {
  ad_id: "id",
  ad_name: "ad_name",
  operator: "operator__business_name",

};


const adFields = ["ad_id","ad_name","operator"];

const AdvertisingView = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [adList, setAdList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-device_count");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [adSelected, setAdSelected] = useState([]);
  const [filterable, setFilterable] = useState(adFields);
  const [operatorList, setOperatorList] = useState([])
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [UnassignModal, setUnAssignModal] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [addeviceList, setAdDeviceList] = useState([]);
  const [adTypeList, setAdTypeList] = useState([]);
  const [editAdPresentationModal, setEditAdPresentationModal] = useState(false);
  const [bigLoader, setBigLoader] = useState(false);
  const [adPresentationModal, setAdPresentationModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [crudLoader, setCrudLoader] = useState(false);
  const [selectedAdScreenType, setSelectedAdScreenType] = useState("");

  const { tableData, addEntry, editEntry, deleteEntry, resetData, setTableData } = useFrontendTable();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const {
    fetchPresentations,
    isLoading,
    addPresentation,
    editPresentation,
    deleteMultiplePresentations,
    presentationList,
    isMutating,
  } = usePresentationCRUD(adSelected?.[0]?.id);

  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    {
      key: "id",
      columnName: "Ad Id",
      type: "text",
      visible: true,
    },

    {
      key: "ad_name",
      columnName: "Ad Name",
      label: "Ad Name",
      type: "text",
      visible: true,
    },
    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    // {
    //   key: "ad_type",
    //   columnName: "Ad Type",
    //   label: "Ad Type",
    //   type: "text",
    //   visible: true,
    // },
    {
      key: "ad_screen",
      columnName: "Ad Screen",
      label: "Ad Screen",
      type: "text",
      visible: true,
    },
    {
      key: "devices",
      columnName: "Visible on Devices",
      type: "text",
      visible: true,
      render: (value) => Array.isArray(value) ? value?.map(x => x.vm_name)?.join(', ') || "---" : "---",
      form: false,
      disableSorting: true
    },
    {
      key: "created_at",
      columnName: "Created At",
      visible: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A")
    },
    {
      key: "updated_at",
      columnName: "Updated At",
      visible: true,
      render: (value) => moment(value).format("MM-DD-YYYY hh:mm:ss A")
    },
  ];

  const dialogFields = [
    {
      key: "ad_name",
      columnName: "Ad Name",
      label: "Ad Name",
      type: "text",
      visible: true,
      required: true,
    },


    {
      key: "operator_id",
      label: "Operator",
      columnName: "Operator",
      type: "autocomplete",
      show: current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      freeSolo: false,
      required: current_user.type === "FO" || current_user.type === "OP" || current_user.type === "DU" ? false : true,
      options: operatorList.map((value) => ({
        label: `${value.business_name} || (${value.customer_type})`,
        value: value.id,
      })),
      visible: true,
      render: (value) => value || "---"
    },
    // {
    //   key: "ad_type",
    //   label: "Type",
    //   visible: true,
    //   required: true,
    //   type: "select",
    //   options: (adTypeList?.ad_type && [
    //     ...Object.entries(adTypeList?.ad_type).map((obj) => ({
    //       label: obj[0],
    //       value: obj[1],
    //     })),
    //   ]) ?? [{ label: "", value: null}],
    //   defaultValue: 'always-on'
    // },
    {
      key: "ad_screen",
      label: "Ad Screen",
      columnName: "Ad Screen",
      type: "select",
      required: true,
      show: true,
      freeSolo: false,
      options: (adTypeList?.ad_screen && [
        ...Object.entries(adTypeList?.ad_screen).map((obj) => ({
          label: obj[0],
          value: obj[1],
        })),
      ]) ?? [{ label: "", value: null }],
      visible: true,
    },
  ];

  const assignDialogFields = [
    {
      key: "device_ids",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      options: deviceList,
    },
  ];


  const UnassignDialogFields = [
    {
      key: "device_idss",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      loading: true,
      required: false,
      extraProps: {
        filterSelectedOptions: true,
      },
      options: addeviceList,
    },
  ];


  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", { params: { all: true, ordering: "business_name" } });
      setOperatorList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };


  const handleAssignVm = async (data) => {

    if (!data?.device_ids || data?.device_ids?.length <= 0) {
      enqueueSnackbar("Please select device(s) from the list.");
      return;
    }

    try {
      setCrudLoader(true)
      await window.axiosIns.post("apply_advert", {
        advert_ids: _.map(adSelected, (x) => x.id),
        device_ids: data.device_ids,
      });

      enqueueSnackbar(
        "Advertisement successfully assigned to vending machine(s)."
      );
      setCrudLoader(false)
      setAssignModal(false);
      getAdvertisementList();
      setAdSelected([]);
    } catch (err) {
      setCrudLoader(false)
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not assign advertisement. Try again."
      );
    }
  };

  const handleUnAssignVm = async (data) => {

    if (!data?.device_idss || data?.device_idss?.length <= 0) {
      enqueueSnackbar("Please select device(s) from the list.");
      return;
    }

    const adDevicesList = _.map(
      addeviceList.filter((val) => data?.device_idss.indexOf(val.value) !== -1),
      (x) => x.value
    );
    try {
      setCrudLoader(true)
      const ad_id = adSelected[0].id;

      await window.axiosIns.put(`advertisement/${ad_id}/unassign`, {
        devices: adDevicesList,
      });

      enqueueSnackbar(
        "Advertisement successfully unassigned to vending machine"
      );
      setCrudLoader(false);
      setUnAssignModal(false);
      getAdvertisementList();
      getAdDevices();
      setAdSelected([]);
    } catch (err) {
      setCrudLoader(false)
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Unassign advertisement. Try again."
      );
    }
  };

  const setup = () => {
    setLoader(true);
    setAdList([]);
    setAdSelected([]);
  };

  const handleRes = (data) => {
    setAdList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setDataCount(data.count);
    setLoader(false);
  };

  const getAdvertisementList = async (order, max, customPage = page) => {
    try {
      const params = {
        ...searchQuery,
        limit: max ? max : rowsPerPage,
        ordering: order ? order : ordering,
        page: customPage + 1,
      };
      setup();
      const { data } = await window.axiosIns("advertisement", { params });
      handleRes(data);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const getDeviceList = async () => {
    try {
      const { data } = await window.axiosIns("device", {
        params: { all: true, ordering: "vm_name" },
      });

      setDeviceList(
        _.map(data?.data?.results, (x) => ({
          value: x.oro_id,
          label: `${x.serial_number} || ${x.vm_name || ""}`,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getAdDevices = async (adSelect) => {
    const ad_id = adSelect && adSelect[0]?.id;
    if (ad_id) {
      try {
        const { data } = await window.axiosIns(`advertisement/${ad_id}`);
        setAdDeviceList(
          _.map(data?.devices, (x) => ({
            // oro_id:x.oro_id,
            value: x.id,
            label: `${x.serial_number} || ${x.vm_name || ""}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getAdvertisementTypeList = async () => {
    try {
      const { data } = await window.axiosIns("advertisement/ad_options", {
        params: { all: true },
      });
      setAdTypeList(data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAdvertisementList();
    getAdvertisementTypeList();
    fetchCompanyList();
    getDeviceList();
  }, []);

  const handleAdd = async (formData) => {
    if (tableData?.length === 0) {
      enqueueSnackbar("Please add AD Presentation to proceed.");
      return;
    }

    let invalid_file = []
    let is_invalid = false
    let media_types = {
      "video": ['mov', 'mp4'],
      "image": ['jpg', 'jpeg', 'png', 'gif']
    }

    const fd = new FormData();

    _.forEach(tableData, ({ file, file_type, order, duration, volume_level }, index) => {
      let file_name = file['name']

      if(!media_types[file_type].includes(file_name?.split('.')[1].toLowerCase())){
        is_invalid = true
        invalid_file = [file_type, file_name]
        return;
      }

      fd.append(`file_name${index}`, file_name)
      fd.append(`file${index}`, file);
      fd.append(`file_type${index}`, file_type);
      fd.append(`order${index}`, order);
      fd.append(`duration${index}`, duration);
      fd.append(`volume_level${index}`, file_type === 'image' ? 0 : volume_level || 0);

    })

    if(is_invalid)
    {
      enqueueSnackbar(`Please upload ${invalid_file[0]} instead of ${invalid_file[1]}.`);
      return
    }

    fd.append("presentation_length", tableData.length)

    try {
      setCrudLoader(true);

      fd.append("ad_name", formData.ad_name)
      fd.append("ad_screen", formData.ad_screen)
      fd.append("ad_type", "always-on")
      fd.append("operator_id", formData.operator_id)


      await window.axiosIns.post("advertisement", fd);

      // reset ad dialog data
      resetData();

      enqueueSnackbar("Advertisement Added successfully.");
      setAddModal(false);
      getAdvertisementList();
      setAdSelected([]);
    } catch (err) {
      console.log(err);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add advertisement. Try again."
      );
    } finally {
      setCrudLoader(false);
      setTableData([])
    }
  };

  const handleEdit = async (data) => {
    if (presentationList?.length === 0) {
      enqueueSnackbar("Please add AD Presentation to proceed.");
      return;
    }
    const ad_id = adSelected[0].id;

    try {
      setCrudLoader(true);
      await window.axiosIns.patch(`advertisement/${ad_id}`, data);

      enqueueSnackbar("Advertisement Edited successfully.");
      setCrudLoader(false);
      setEditModal(false);
      getAdvertisementList();
      setAdSelected([]);
    } catch (err) {
      console.log(err);
      setCrudLoader(false)
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not edit advertisement. Try again."
      );
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
        let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
        setup();
        setPage(0);
        window
        .axiosIns("advertisement", {
          params: { ...searchFilter, limit: rowsPerPage },
        })
            .then((data = {}) => {
                handleRes(data.data);
            }).catch(err => {
                setLoader(false);
            })
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("advertisement", {
        params: { ...searchFilter, limit: rowsPerPage },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDelete = async () => {
    try {
      setCrudLoader(true)
      await Promise.all(
        adSelected.map((val) =>
          window.axiosIns.delete(`advertisement/${val.id}`)
        )
      );

      enqueueSnackbar("Advertisement(s) deleted successfully.");
      setCrudLoader(false);
      setDeleteModal(false);
      getAdvertisementList();
    } catch (err) {
      setCrudLoader(false)
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not delete Advertisement(s). Try again."
      );
    }
  };

  const checkAssignButton = () => {
    const checkUnique = _.uniqBy(adSelected, 'ad_screen');

    if (checkUnique.length !== adSelected.length) {
      return true;
    }
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Advertising"
        description="
All Advertisements that are created are displayed here. You can also edit, delete, assign and unassign the ads from here."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU'}
          />
          <AllocateButton
            disabled={current_user.type === 'SU' || adSelected.length <= 0 || checkAssignButton()}
            className="mr-3"
            label="Assign"
            onClick={() => setAssignModal(true)}
          />
          <UnAllocateButton
            disabled={current_user.type === 'SU' || adSelected.length !== 1}
            className="mr-3"
            label="Unassign"
            onClick={() => {
              setUnAssignModal(true);
            }}
          />
          <EditButton
          disabled={adSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => {
              setEditModal(true);
              fetchPresentations();
            }}
          />
          <DeleteButton
            disabled={adSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={adFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={adList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getAdvertisementList(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getAdvertisementList(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          // onChangePage={(page) => console.log(page)}
          selectedRecords={adSelected}
          rowOnePage={10}
          onChangeSelected={(adSelected) => {
            setAdSelected(adSelected);
            getAdDevices(adSelected);
          }}
        />

        <CrudDialog
          title="Add Advertisement"
          okText="Add Advertisement"
          fields={dialogFields}
          description="Please fill in the details below."
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          extraButtonText="Ad Presentation"
          onExtraButton={() => {
            setAdPresentationModal(true);
          }}
          crudLoader={crudLoader}
          open={addModal}
          onClose={() => setAddModal(false)}
          onFieldChange={(field, val) => {
            if (field?.key == "ad_screen") {
              setSelectedAdScreenType(val);
            }
          }}
        />

        <CrudDialog
          title="Assign Vending machine(s)"
          okText="Assign"
          description="Please fill the details below."
          fields={assignDialogFields}
          crudLoader={crudLoader}
          onSubmit={(values) => {
            handleAssignVm(values);
          }}
          open={assignModal}
          onClose={() => setAssignModal(false)}
        />
        <CrudDialog
          title="Unassign Vending machine(s)"
          okText="Update"
          description="Please fill the details below."
          fields={UnassignDialogFields}
          crudLoader={crudLoader}
          values={{
            device_idss: addeviceList && addeviceList.map((x) => x.value),
          }}
          onSubmit={(values) => {
            handleUnAssignVm(values);
          }}
          open={UnassignModal}
          onClose={() => setUnAssignModal(false)}
        />

        <CrudDialog
          title="Edit Advertisement"
          okText="Save"
          description="Please edit the details below."
          fields={dialogFields}
          values={adSelected[0]}
          crudLoader={crudLoader}
          extraButtonText="Ad Presentation"
          onExtraButton={() => {
            setEditAdPresentationModal(true);
            fetchPresentations();
          }}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
          onFieldChange={(field, val) => {
            if (field?.key == "ad_screen") {
              setSelectedAdScreenType(val);
            }
          }}
          onOpen={() => {
            setSelectedAdScreenType(adSelected[0]?.ad_screen);
          }}
        />

        <CrudDialog
          title="Delete Advertisement"
          description="Are you sure you want to delete the Advertisement?"
          okText="Delete"
          crudLoader={crudLoader}
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />

        <DialogWithTable
          open={adPresentationModal}
          onClose={() => {
            setAdPresentationModal(false);
          }}
          onAdd={addEntry}
          onEdit={editEntry}
          onDelete={deleteEntry}
          tableData={tableData}
          selectedAdScreenType={selectedAdScreenType}
        />

        <DialogWithTable
          open={editAdPresentationModal}
          onClose={() => {
            setEditAdPresentationModal(false)
          }}
          onAdd={addPresentation}
          onEdit={editPresentation}
          onDelete={deleteMultiplePresentations}
          tableLoading={isLoading}
          tableData={presentationList}
          selectedAdScreenType={selectedAdScreenType}
        />
      </div>

      {(isMutating || bigLoader) && <BigLoader />}

    </div>
  );
};
export default withStyles({}, { withTheme: true })(AdvertisingView);

